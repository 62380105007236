<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="sds-modal-body-new mx-auto">
        <div class="modal-header pt-0">
          <!-- <p class="modal-title">ORDER BOOK</p> -->
          <button type="button" class="close" v-on:click="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container form-signin3">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 50px; padding-right: 50px"
            >
              <!-- <form class="form-inline transparent-form p10 border-page m-t-2">
                <div class="col-lg-12 row">
                  <div class="col-lg-10 row">
                    <div class="input-group mb-2 mr-sm-2"> -->
              <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div> -->
              <!-- <datepicker
                        v-model="fromDate"
                        :upper-limit="toDate"
                        class="form-control"
                        placeholder="From Date"
                      />
                    </div>
                    <div class="input-group mb-2 mr-sm-2"> -->
              <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div> -->
              <!-- <datepicker
                        v-model="toDate"
                        :lower-limit="fromDate"
                        class="form-control"
                        placeholder="To Date"
                      />
                    </div> -->
              <!-- <div
                      class="col-lg-2 input-group mb-2 mr-sm-2 custom-control custom-radio d-block float-left"
                    >
                      <Field
                        v-model="order_type"
                        type="radio"
                        value="PENDING"
                        id="gross_partnership"
                        name="net_gross_partner"
                        v-on:change="getList()"
                        class="custom-control-input ng-pristine ng-untouched ng-valid"
                        checked="checked"
                        style="height: unset !important"
                      />
                      <label
                        style="font-size: 15px"
                        for="gross_partnership"
                        class="custom-control-label mt-1"
                        >Pending Orders</label
                      >
                    </div>
                    <div
                      class="col-lg-2 input-group mb-2 mr-sm-2 custom-control custom-radio d-block float-left"
                    >
                      <Field
                        v-model="order_type"
                        type="radio"
                        value="EXECUTED"
                        id="gross_partnership2"
                        v-on:change="getList()"
                        name="net_gross_partner"
                        class="custom-control-input ng-pristine ng-untouched ng-valid"
                        checked="checked"
                        style="height: unset !important"
                      />
                      <label
                        style="font-size: 15px"
                        for="gross_partnership2"
                        class="custom-control-label mt-1"
                        >Completed Orders</label
                      >
                    </div>
                  </div>

                  <div class="col-lg-2 ml-4 text-right">
                    <button
                      id="btn-search"
                      type="button"
                      v-on:click="getList(1)"
                      class="btn btn-primary mb-2"
                    >
                      Search
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      v-on:click="reset"
                      class="btn btn-secondary mb-2"
                    >
                      Reset
                    </button>
                  </div> -->
              <!-- </div>
              </form> -->
            </div>
          </div>

          <div
            class="row"
            style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
          >
            <div class="col-lg-12 row ml-1">
              <p
                style="
                  color: black;
                  text-align: justify;
                  font-weight: bold;
                  font-size: 12px;
                  margin: 0px !important;
                  margin-top: 7px !important;
                "
              >
                USER LOGIN
              </p>
              <!-- <div class="col-lg-2">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="SEARCH"
                  v-model="keyword"
                  v-on:keyup="getList()"
                />
              </div> -->
            </div>
            <div
              class="col-md-12 bg-light sticky-table-header"
              style="height: 400px"
            >
              <table
                id="table"
                data-toggle="table"
                data-search="true"
                data-filter-control="true"
                data-toolbar="#toolbar"
                class="table table-sm text-center text-white table-bordered-summary product-list table-hover"
              >
                <thead>
                  <tr>
                    <th
                      class="head sorting"
                      id="id-user_id"
                      v-on:click="sorting('user_id', 'id-user_id')"
                    >
                      User Id
                    </th>
                    <th
                      class="head sorting"
                      id="id-source"
                      v-on:click="sorting('source', 'id-source')"
                    >
                      Source
                    </th>
                    <th
                      class="head sorting"
                      id="id-ip"
                      v-on:click="sorting('ip', 'id-ip')"
                    >
                      Ip
                    </th>
                    <th
                      class="head sorting"
                      id="id-created_at"
                      v-on:click="sorting('created_at', 'id-created_at')"
                    >
                      Login Time
                    </th>
                  </tr>
                </thead>
                <tbody v-show="!loader">
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="body-latest" style="text-align: initial">
                      {{ data.user_id }}
                    </td>
                    <td class="body-latest" style="text-align: initial">
                      {{ data.source }}
                    </td>
                    <td class="body-latest" style="text-align: initial">
                      {{ data.ip }}  
                    </td>
                    <td class="body-latest" style="text-align: initial">
                      {{ $helperService.getDateTime(data.created_at) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sds-modal-background"></div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
// import Datepicker from "vue3-datepicker";
export default {
  name: "TextModal",
  components: {
    DeleteModal,
    ErrorComponent,
    // Datepicker,
  },
  data() {
    return {
      id: "",
      isShowModal: false,
      loader: true,
      list: [],
      fromDate: "",
      order_type: "PENDING",
      toDate: "",
      keyword: "",
      type: "",
    };
  },
  mounted() {},
  methods: {
    updateQuantity(lot, lot_size, index) {
      var qty = lot * lot_size;
      for (var i in this.list) {
        if (i == index) {
          this.list[i].quantity = qty;
        }
      }
    },
    editRow(index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].isEdit = true;
        } else {
          this.list[i].isEdit = false;
        }
      }
    },
    closeIsEdit() {
      for (var i in this.list) {
        this.list[i].isEdit = false;
      }
    },
    updateRow(index) {
      for (var i in this.list) {
        if (index == i) {
          this.list[i].isEdit = false;
          var obj = this.list[i];
        }
      }
      this.$api
        .postAPI({
          _action: "/update-trade",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    executeOrder(id, index) {
      var obj = {};
      obj.id = id;
      this.$api
        .postAPI({
          _action: "/update-trade-status",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.list.splice(index, 1);
        })
        .catch(() => {});
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.id, obj.order_by, obj.sort_by);
    },
    getList(id, orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/all-user-login-log-list/" + id,
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          for (var i in this.list) {
            this.list[i].isEdit = false;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.getList();
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Order",
        "Are you sure  want to delete order",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/trade/" + detail.id);
    },
    showModal(id) {
      this.isShowModal = true;
      this.id = id;
      this.getList(id);
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
