<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600; font-size: 16px">
            Branch Report
          </h2>
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div
              class="col-lg-12 col-sm-12 row"
              style="padding-right: 0px !important">
              <div class="col-lg-6 col-sm-6 mt-1">
                <Multiselect
                  mode="tags"
                  trackBy="remark"
                  label="remark"
                  valueProp="remark"
                  class="register-select"
                  placeholder="WEEKS VALAN"
                  v-model="remarks"
                  :options="weekList"
                  searchable="true" />
              </div>
              <div
                class="col-2"
                style="text-align: left"
                v-if="role == 'ADMIN'">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="server_code"
                  class="register-select"
                  placeholder="Super Master"
                  v-model="code"
                  :options="supermasterList"
                  searchable="true" />
              </div>
            </div>

            <div
              class="col-lg-12 col-sm-12 mt-2 row"
              style="padding-right: 0px !important">
              <!-- <div class="col-lg-10 row"> -->

              <div class="col-lg-2 col-sm-3 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder=" Exchange"
                  v-model="exchange"
                  :options="exchanges"
                  @select="getScriptName(exchange)"
                  searchable="true" />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1">
                <Multiselect
                  mode="multiple"
                  trackBy="name"
                  label="name"
                  valueProp="name"
                  class="register-select"
                  placeholder=" Script"
                  v-model="script_id"
                  :options="script_list"
                  searchable="true"
                  :multipleLabel="selectedOption" />
              </div>
              <div
                class="col-lg-2 mt-1"
                v-if="userRole == 'SUPER_MASTER' || userRole == 'MANAGER'">
                <Multiselect
                  mode="multiple"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder="MASTER"
                  v-model="master_id"
                  :options="master_list"
                  searchable="true"
                  @select="selectALLMaster($event)"
                  :multipleLabel="selectedOption" />
              </div>
              <div class="col-lg-2 mt-1" v-if="userRole != 'BROKER'">
                <Multiselect
                  mode="multiple"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder="BROKER"
                  v-model="broker_id"
                  :options="brokerList"
                  searchable="true"
                  @select="selectALLBroker($event)"
                  :multipleLabel="selectedOption" />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1" v-if="role != 'USER'">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="user_id"
                  :options="client_list"
                  @select="selectALLClient($event)"
                  searchable="true" />
              </div>

              <div class="col-lg-3 col-sm-2 mt-2 col-xl-auto handle-radi row">
                <div class="custom-control col-5 custom-radio mb-1">
                  <input
                    type="radio"
                    value="ALL"
                    id="report_all"
                    name="report_type"
                    v-model="report_type"
                    class="custom-control-input ng-pristine mt-1 ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    "
                    for="report_all"
                    class="custom-control-label lable-text"
                    >Summary</label
                  >
                </div>
                <div class="custom-control col-7 custom-radio">
                  <input
                    v-model="report_type"
                    type="radio"
                    value="CONSOLIDATE"
                    id="report_consolidate"
                    name="report_type"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    "
                    for="report_consolidate"
                    class="custom-control-label lable-text"
                    >Consolidate</label
                  >
                </div>
                <span class="text-error"></span>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-lg-12 col-sm-12 row mt-1 justify-content-end">
          <button
            id="btn-search"
            type="button"
            v-on:click="getList()"
            class="btn btn-primary mb-2">
            Search
          </button>
          <button
            type="button"
            v-on:click="reset"
            class="btn btn-secondary mb-2 ml-2">
            Reset
          </button>
          <button
            type="button"
            v-show="list.length > 0"
            class="btn btn-primary mb-2 ml-2"
            v-on:click="downloadPdf(report_type)">
            Download
          </button>
        </div>
        <div
          class="col-md-12 bg-light border-page"
          v-if="report_type == 'CONSOLIDATE'">
          <div class="row col-12">
            <div style="text-align: left" class="col-6"></div>
            <div style="text-align: right" class="col-6">
              <span style="padding: 3px 15px; border-radius: 3px">{{
                dateRange
              }}</span>
            </div>
          </div>

          <table
            v-if="consolidateList.length > 1"
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th
                  class="head sorting"
                  id="id-Party"
                  v-on:click="sorting2('name', 'id-Party')">
                  Party Name
                </th>
                <th
                  class="head sorting"
                  id="id-amount"
                  v-on:click="sorting2('amount', 'id-amount')">
                  MTM Amt
                </th>
                <th
                  class="head sorting"
                  id="id-brokerage"
                  v-on:click="sorting2('brokerage', 'id-brokerage')">
                  Brok Amount
                </th>
                <th
                  class="head sorting"
                  id="id-net_amount"
                  v-on:click="sorting2('net_amount', 'id-net_amount')">
                  Total Amt
                </th>
                <th
                  class="head sorting"
                  id="id-brBrokerage"
                  v-on:click="sorting2('brBrokerage', 'id-brBrokerage')">
                  Br Brok
                </th>
                <th
                  class="head sorting"
                  id="id-sharing"
                  v-on:click="sorting2('sharing', 'id-sharing')">
                  Share %
                </th>
                <th
                  class="head sorting"
                  id="id-shareAmt"
                  v-on:click="sorting2('shareAmt', 'id-shareAmt')">
                  Br Share
                </th>
                <th
                  class="head sorting"
                  id="id-netAmt"
                  v-on:click="sorting2('netAmt', 'id-netAmt')">
                  Net Amt
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in consolidateList"
                :key="index"
                style="cursor: pointer">
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="text-align: left; font-size: 13px !important">
                  {{ data.name }}
                </td>
                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.amount.toFixed(2))
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="
                    text-align: right;
                    color: red;
                    font-size: 13px !important;
                  ">
                  {{ data.brokerage > 0 ? "-" : "" }}
                  {{
                    $helperService.getThousandConverter(
                      data.brokerage.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.net_amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.net_amount.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="
                    text-align: right;
                    color: blue;
                    font-size: 13px !important;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      data.brBrokerage.toFixed(2)
                    )
                  }}
                </td>
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{ data.type == "total" ? "" : data.sharing }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.shareAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.shareAmt.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.netAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.netAmt.toFixed(2))
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            id="tableBroker"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th
                  class="head sorting"
                  id="id-Party"
                  v-on:click="sorting3('name', 'id-Party')">
                  Party Name
                </th>
                <th
                  class="head sorting"
                  id="id-amount"
                  v-on:click="sorting3('amount', 'id-amount')">
                  MTM Amt
                </th>
                <th
                  class="head sorting"
                  id="id-brokerage"
                  v-on:click="sorting3('brokerage', 'id-brokerage')">
                  Brok Amount
                </th>
                <th
                  class="head sorting"
                  id="id-net_amount"
                  v-on:click="sorting3('net_amount', 'id-net_amount')">
                  Total Amt
                </th>
                <th
                  class="head sorting"
                  id="id-brBrokerage"
                  v-on:click="sorting3('brBrokerage', 'id-brBrokerage')">
                  Br Brok
                </th>
                <th
                  class="head sorting"
                  id="id-sharing"
                  v-on:click="sorting3('sharing', 'id-sharing')">
                  Share %
                </th>
                <th
                  class="head sorting"
                  id="id-shareAmt"
                  v-on:click="sorting3('shareAmt', 'id-shareAmt')">
                  Br Share
                </th>
                <th
                  class="head sorting"
                  id="id-netAmt"
                  v-on:click="sorting3('netAmt', 'id-netAmt')">
                  Net Amt
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in consolidateBrokerList"
                :key="index"
                style="cursor: pointer">
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="text-align: left; font-size: 13px !important">
                  {{ data.name }}
                </td>
                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.amount.toFixed(2))
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="
                    text-align: right;
                    color: red;
                    font-size: 13px !important;
                  ">
                  {{ data.brokerage > 0 ? "-" : "" }}
                  {{
                    $helperService.getThousandConverter(
                      data.brokerage.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.net_amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.net_amount.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="
                    text-align: right;
                    color: blue;
                    font-size: 13px !important;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      data.brBrokerage.toFixed(2)
                    )
                  }}
                </td>
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{ data.type == "total" ? "" : data.sharing }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.shareAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.shareAmt.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.netAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.netAmt.toFixed(2))
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-12 bg-light border-page" v-if="report_type == 'ALL'">
          <div v-for="(report, index1) in list" :key="index1">
            <div class="row col-12">
              <div style="text-align: left" class="col-6">
                <span
                  style="
                    background: #17fffb;
                    padding: 3px 15px;
                    border-radius: 3px;
                  ">
                  {{ report.name_code }}</span
                >
              </div>

              <div style="text-align: right" class="col-5">
                <span style="padding: 3px 15px; border-radius: 3px">{{
                  dateRange
                }}</span>
              </div>
              <div style="text-align: right" class="col-1">
                <span
                  v-on:click="downloadPdf(report_type, index1)"
                  style="padding: 3px 15px; border-radius: 3px; cursor: pointer"
                  ><i class="fa fa-download" aria-hidden="true"></i>
                </span>
              </div>
            </div>

            <table
              id="table"
              style="margin-top: 16px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th
                    class="head sorting"
                    id="id-Party"
                    v-on:click="sorting('name', 'id-Party', index1)">
                    Party Name
                  </th>
                  <th
                    class="head sorting"
                    id="id-amount"
                    v-on:click="sorting('amount', 'id-amount', index1)">
                    MTM Amt
                  </th>
                  <th
                    class="head sorting"
                    id="id-brokerage"
                    v-on:click="sorting('brokerage', 'id-brokerage', index1)">
                    Brok Amount
                  </th>
                  <th
                    class="head sorting"
                    id="id-net_amount"
                    v-on:click="sorting('net_amount', 'id-net_amount', index1)">
                    Total Amt
                  </th>
                  <th
                    class="head sorting"
                    id="id-brBrokerage"
                    v-on:click="
                      sorting('brBrokerage', 'id-brBrokerage', index1)
                    ">
                    Br Brok
                  </th>
                  <th
                    class="head sorting"
                    id="id-Party"
                    v-on:click="sorting('name', 'id-Party', index1)">
                    Share %
                  </th>
                  <th
                    class="head sorting"
                    id="id-sharing"
                    v-on:click="sorting('sharing', 'id-sharing', index1)">
                    Br Share
                  </th>
                  <th
                    class="head sorting"
                    id="id-netAmt"
                    v-on:click="sorting('netAmt', 'id-netAmt', index1)">
                    Net Amt
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in report.list"
                  :key="index"
                  style="cursor: pointer">
                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600;'
                    "
                    style="text-align: left; font-size: 13px !important">
                    {{ data.name }}
                  </td>
                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.amount < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 13px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.amount.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600;'
                    "
                    style="
                      text-align: right;
                      color: red;
                      font-size: 13px !important;
                    ">
                    {{ data.brokerage > 0 ? "-" : "" }}
                    {{
                      $helperService.getThousandConverter(
                        data.brokerage.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.net_amount < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 13px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.net_amount.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600; '
                    "
                    style="
                      text-align: right;
                      color: blue;
                      font-size: 13px !important;
                    ">
                    {{
                      $helperService.getThousandConverter(
                        data.brBrokerage.toFixed(2)
                      )
                    }}
                  </td>
                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600; '
                    "
                    style="text-align: right; font-size: 13px !important">
                    {{ data.type == "total" ? "" : data.sharing }}
                  </td>

                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.shareAmt < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 13px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.shareAmt.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.netAmt < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 13px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.netAmt.toFixed(2)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ErrorComponent @retry="getList(0)" ref="errorComponent" />
    </div>
    <!-- <PrintBranchDetailReport
      ref="printBranchDetailReport"></PrintBranchDetailReport> -->
    <PrintBranchConsolidatedReport
      ref="printBranchConsolidatedReport"></PrintBranchConsolidatedReport>
    <PrintBranchSummaryReport
      ref="printBranchSummaryReport"></PrintBranchSummaryReport>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
// import PrintBranchDetailReport from "../../components/PrintBranchDetailReport.vue";
import PrintBranchConsolidatedReport from "../../components/PrintBranchConsolidatedReport.vue";
import PrintBranchSummaryReport from "../../components/PrintBranchSummaryReport.vue";

export default {
  components: {
    ErrorComponent,
    // PrintBranchDetailReport,
    PrintBranchConsolidatedReport,
    PrintBranchSummaryReport,
    Multiselect,
  },
  name: "TradeSummaryReport",
  data() {
    return {
      loader: true,
      list: [],
      supermasterList: [],
      fromDate: "",
      report_type: "ALL",
      toDate: "",
      weekList: [],
      broker_list: [],
      master_list: [],
      remarks: [],
      client_list: [],
      script_list: [],
      broker_id: [],
      master_id: [],
      client_id: "",
      code: "",
      script_id: [],
      exchange: "",
      dateRange: "",
      consolidateList: [],
      consolidateBrokerList: [],
      role: localStorage.getItem("role"),
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
      ],
    };
  },
  computed: {
    brokerList() {
      if (
        this.master_id &&
        this.master_id.length > 0 &&
        this.master_id != "ALL"
      ) {
        var temp = [];
        for (var broker of this.broker_list) {
          for (var masterId of this.master_id)
            if (broker.master_id == masterId) {
              temp.push(broker);
            }
        }

        return temp;
      }

      return this.broker_list;
    },
    userRole() {
      return this.$store.getters.getUser?.role;
    },
    userId() {
      return this.$store.getters.getUser?.role == "SUPER_MASTER"
        ? this.$store.getters.getUser?.server_code
        : this.$store.getters.getUser?.id;
    },
    serverCode() {
      return this.$store.getters.getUser?.server_code;
    },
  },
  mounted() {
    this.getSuperMasterList(0);
    this.checkAccess();
    this.getWeekList();
    this.getClientList();
    this.getBrokerList();
    this.getMasterList();
  },
  methods: {
    checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_branchReport == 0 && this.role != "ADMIN") {
        this.$router.push("/access-denied");
      }
    },
    sorting(sortBy, id, index) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      var arr = [];
      for (var i in this.list) {
        if (index == i) {
          arr = this.list[i];
        }
      }

      var obj = arr.list[arr.list.length - 1];
      arr.list.splice(arr.list.length - 1, 1);
      var arr3 = arr.list;
      var $this = this;
      setTimeout(() => {
        var arr2 = $this.$helperService.localSorting(arr3, sortBy, id);
        for (var i in $this.list) {
          if (index == i) {
            $this.list[i].list = arr2;
            $this.list[i].list.push(obj);
          }
        }
        $this.$refs.errorComponent.updateFormLoader(false);
        $this.loader = false;
      }, 1000);
    },
    sorting2(sortBy, id) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      var obj = this.consolidateList[this.consolidateList.length - 1];
      this.consolidateList.splice(this.consolidateList.length - 1, 1);
      var arr = this.consolidateList;
      var $this = this;
      setTimeout(() => {
        $this.consolidateList = $this.$helperService.localSorting(
          arr,
          sortBy,
          id
        );
        $this.consolidateList = arr;
        $this.consolidateList.push(obj);
        $this.$refs.errorComponent.updateFormLoader(false);
        $this.loader = false;
      }, 1000);
    },
    getSuperMasterList() {
      this.$api
        .getAPI({
          _action: "/super-master-list",
        })
        .then((res) => {
          this.supermasterList = res;
          // for (var i in this.supermasterList) {
          //   {
          //     if (this.details.user_id == this.supermasterList[i].id) {
          //       this.user_detail = this.supermasterList[i];
          //     }
          //   }
          // }
        })
        .catch(() => {});
    },
    sorting3(sortBy, id) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      var obj =
        this.consolidateBrokerList[this.consolidateBrokerList.length - 1];
      this.consolidateBrokerList.splice(
        this.consolidateBrokerList.length - 1,
        1
      );
      var arr = this.consolidateBrokerList;
      var $this = this;
      setTimeout(() => {
        $this.consolidateBrokerList = $this.$helperService.localSorting(
          arr,
          sortBy,
          id
        );
        $this.consolidateBrokerList = arr;
        $this.consolidateBrokerList.push(obj);
        $this.$refs.errorComponent.updateFormLoader(false);
        $this.loader = false;
      }, 1000);
    },
    getWeekList() {
      this.$api
        .getAPI({
          _action: "/all-week-list",
        })
        .then((res) => {
          // this.weekList.push({ remark: "Live Report" });
          for (var obj of res.list) {
            this.weekList.push(obj);
          }
        })
        .catch(() => {});
    },

    downloadPdf(type, index) {
      if (type == "ALL") {
        var arr = [];
        if (index || index == 0) {
          for (var i in this.list) {
            if (index == i) {
              arr.push(this.list[i]);
            }
          }

          this.$refs.printBranchSummaryReport.setDataAndPrint(arr);
        } else {
          this.$refs.printBranchSummaryReport.setDataAndPrint(this.list);
        }
      }
      if (type == "CONSOLIDATE") {
        this.$refs.printBranchConsolidatedReport.setDataAndPrint(
          this.consolidateList,
          this.consolidateBrokerList
        );
      }
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          // this.broker_list = res;
          // for (var i in this.broker_list) {
          //   this.broker_list[i].new_name =
          //     this.broker_list[i].name + "(" + this.broker_list[i].code + ")";
          // }
          this.broker_list.push({
            id: "ALL",
            new_name: "ALL",
            // code: "ALL",
            name: "ALL",
          });
          for (var i in res) {
            res[i].new_name = res[i].name + "(" + res[i].code + ")";
            this.broker_list.push(res[i]);
          }
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list.push({
            id: "ALL",
            new_name: "ALL",
            // code: "ALL",
            name: "ALL",
          });
          for (var i in res) {
            res[i].new_name = res[i].name + "(" + res[i].code + ")";
            this.master_list.push(res[i]);
          }
        })
        .catch(() => {});
    },
    getScriptName(exchange) {
      this.script_list = [];
      if (exchange) {
        this.$api
          .getAPI({
            _action: "/script-list/" + exchange,
          })
          .then((res) => {
            this.script_list = res;
          })
          .catch(() => {});
      }
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          // this.client_list.push({
          //   id: "ALL",
          //   new_name: "ALL",
          //   // code: "ALL",
          //   name: "ALL",
          // });
          for (var i in res) {
            res[i].new_name = res[i].name + "(" + res[i].code + ")";
            this.client_list.push(res[i]);
          }
        })
        .catch(() => {});
    },

    getList() {
      if (this.remarks.length <= 0) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please select Valan",
        });
        return;
      }
      this.list = [];
      this.consolidateList = [];
      this.consolidateBrokerList = [];
      var searchObj = {};
      searchObj.role = this.userRole;
      this.dateRange = this.remarks.toString().replaceAll(",", ", ");
      this.$refs.errorComponent.updateFormLoader(true);

      if (this.master_id.length > 0) {
        if (this.master_id == "ALL") {
          // searchObj.master_id = "";
        } else {
          searchObj.master_id = this.master_id.toString();
        }
      }
      if (this.broker_id.length > 0) {
        if (this.broker_id == "ALL") {
          // searchObj.broker_id = "";
        } else {
          searchObj.broker_id = this.broker_id.toString();
        }
      }
      if (this.user_id) {
        searchObj.client_id = this.user_id;
      }
      if (this.script_id.length > 0) {
        searchObj.script =
          "'" + this.script_id.toString().replaceAll(",", "','") + "'";
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      searchObj.remarks =
        "'" + this.remarks.toString().replaceAll(",", "','") + "'";
      searchObj.user_id = this.userId;
      searchObj.code = this.serverCode;

      if (this.userRole == "MASTER") {
        searchObj.master_id = this.userId;
      } else if (this.userRole == "BROKER") {
        searchObj.broker_id = this.userId;
      }
      if (this.code) {
        searchObj.code = this.code;
      }
      this.$api
        .getAPI({
          _action: "/branch",
          _body: searchObj,
          _isExternal: true,
        })
        .then((res) => {
          this.list = [];
          if (res) {
            for (var obj of res) {
              obj.name_code =
                obj.code + " " + obj.name.toUpperCase() + "(" + obj.role + ")";
              let tempObj = {
                type: "total",
                name: obj.code + " " + obj.name.toUpperCase(),
                amount: 0,
                brokerage: 0,
                net_amount: 0,
                brBrokerage: 0,
                sharing: "",
                shareAmt: 0,
                netAmt: 0,
              };

              for (var trade of obj.list) {
                trade.brBrokerage = trade.br_brokerage == 0 ? 0 : trade.brokerage - trade.br_brokerage;
                trade.brAmt = trade.amount - Math.abs(trade.br_brokerage);
                trade.shareAmt = trade.br_brokerage == 0 ?  -((trade.net_amount * trade.sharing) / 100) :  -((trade.brAmt * trade.sharing) / 100);
                trade.netAmt =
                  trade.net_amount + trade.brBrokerage + trade.shareAmt;
                tempObj.net_amount += trade.net_amount;
                tempObj.amount += trade.amount;
                tempObj.sharing = trade.sharing;
                tempObj.shareAmt += trade.shareAmt;
                tempObj.netAmt += trade.netAmt;
                tempObj.brokerage += trade.brokerage;
                tempObj.brBrokerage += trade.brBrokerage;
              }

              var isAdd = true;
              if (this.master_id.length > 0) {
                isAdd = false;
                for (var masterId of this.master_id) {
                  if (obj.id == masterId) {
                    isAdd = true;
                  } else if (this.master_id == "ALL") {
                    isAdd = true;
                  }
                }
              }

              if (isAdd) {
                if (obj.role == "BROKER") {
                  this.consolidateBrokerList.push({ ...tempObj, type: "" });
                } else {
                  this.consolidateList.push({ ...tempObj, type: "" });
                }
              }
              console.log("object", this.consolidateList);
              obj.list.push(tempObj);
              this.list.push(obj);
            }

            let tempObj = {
              type: "total",
              name: "Grand Total",
              amount: 0,
              brokerage: 0,
              net_amount: 0,
              brBrokerage: 0,
              sharing: "",
              shareAmt: 0,
              netAmt: 0,
            };

            let tempBrObj = {
              type: "total",
              name: "Grand Total",
              amount: 0,
              brokerage: 0,
              net_amount: 0,
              brBrokerage: 0,
              sharing: "",
              shareAmt: 0,
              netAmt: 0,
            };

            for (let obj1 of this.consolidateList) {
              tempObj.net_amount += obj1.net_amount;
              tempObj.amount += obj1.amount;
              tempObj.shareAmt += obj1.shareAmt;
              tempObj.netAmt += obj1.netAmt;
              tempObj.brokerage += obj1.brokerage;
              tempObj.brBrokerage += obj1.brBrokerage;
            }

            for (let obj2 of this.consolidateBrokerList) {
              tempBrObj.net_amount += obj2.net_amount;
              tempBrObj.amount += obj2.amount;
              tempBrObj.shareAmt += obj2.shareAmt;
              tempBrObj.netAmt += obj2.netAmt;
              tempBrObj.brokerage += obj2.brokerage;
              tempBrObj.brBrokerage += obj2.brBrokerage;
            }

            this.consolidateList.push(tempObj);
            this.consolidateBrokerList.push(tempBrObj);
          }
        })
        .catch(() => {});

      this.$refs.errorComponent.updateFormLoader(false);
    },
    selectedOption(value) {
      if (value.length == 1) {
        return value[0].code
          ? value[0].name + " (" + value[0].code + ")"
          : value[0].name;
      }

      return value.length + " selected";
    },
    selectALLMaster(e) {
      if (e == "ALL") {
        this.master_id = ["ALL"];
      } else {
        for (var i in this.master_id) {
          if (this.master_id[i] == "ALL") {
            this.master_id.splice(i, 1);
          }
        }
      }
    },
    selectALLBroker(e) {
      if (e == "ALL") {
        this.broker_id = ["ALL"];
      } else {
        for (var i in this.broker_id) {
          if (this.broker_id[i] == "ALL") {
            this.broker_id.splice(i, 1);
          }
        }
      }
    },
    selectALLClient(e) {
      if (e == "ALL") {
        this.user_id = ["ALL"];
      } else {
        this.user_id = e;
      }
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.exchange = "";
      this.client_id = "";
      this.script_id = "";
      this.authority_id = "";
    },
  },
};
</script>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
