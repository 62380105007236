<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2 class="text-start" style="color: rgb(29, 68, 91); font-weight: 600; font-size: 16px;">
            Ledger <br /> {{ name }}
          </h2>
          <button style="color: white;position: absolute;right: 50px;top: 16px;" type="button" v-on:click="download"
            class="btn btn-primary mb-2">
            Download
          </button>
        </div>
      </div>

      <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div v-for="(obj, m) in list" :key="m">
            <span>{{ obj.trading_symbol }}</span>
            <table id="table" style="margin-top: 16px" data-toggle="table" data-search="true" data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">Date Time</th>
                  <th class="head">Type</th>
                  <th class="head">Lot</th>
                  <th class="head">Buy Qty</th>
                  <th class="head">Sell Qty</th>
                  <th class="head">Rate</th>
                  <th class="head">Net Rate</th>
                  <th class="head">Amount</th>
                </tr>
              </thead>
              <tbody v-show="!loader">
                <tr v-for="(data, index) in obj.list" :key="index">
                  <td colspan="3" v-if="data.datatype == 'Total'" class="total">
                    {{ data.title }}
                  </td>
                  <td v-if="data.datatype != 'Total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                    {{ $helperService.getDateTime(data.created_at) }}
                  </td>
                  <td v-if="data.datatype != 'Total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                    {{ data.type ? data.type : "" }}
                  </td>

                  <td v-if="data.datatype != 'Total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                    {{ data.lot ? data.lot.toFixed(2) : "" }}
                  </td>

                  <td v-if="data.datatype != 'Total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" style="color: blue"
                    class="body">
                    {{
                      data.quantity && data.order_action == "BUY"
                      ? data.quantity
                      : '-'
                    }}
                  </td>
                  <td v-if="data.datatype != 'Total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" style="color: red"
                    class="body">
                    {{
                      data.quantity && data.order_action == "SELL"
                      ? data.quantity
                      : '-'
                    }}
                  </td>

                  <td v-if="data.datatype == 'Total'" class="total">
                    {{ $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(data.bq)) }}
                  </td>
                  <td v-if="data.datatype == 'Total'" class="total">
                    {{ $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(data.sq)) }}
                  </td>


                  <td :class="data.datatype != 'Total' ? 'body' : 'total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''">
                    {{ data.price ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(data.price, 0)) : 0 }}
                  </td>
                  <td :class="data.datatype != 'Total' ? 'body' : 'total'"
                    :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''">
                    {{ data.net_price ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(data.net_price, 0)) : 0 }}
                  </td>


                  <td v-if="data.datatype != 'Total'"
                    :style="(data.order_action == 'SELL' ? 'color: red' : 'color: blue') + ';' + (data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : '')"
                    class="body">
                    {{
                      data.order_action == "BUY"
                      ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(
                        data.net_price * data.quantity
                      ))
                      : ('-' + $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(
                        data.net_price * data.quantity))
                      )
                    }}
                  </td>

                  <td v-if="data.datatype == 'Total'" class="total">
                    {{ $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(data.netamount, 0)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 row" style="margin-top: -16px;margin-left: 2px;padding: 0px;">
            <div class="col-6 border-1 row">
              <div class="col-6">
                <b> Buy Turnover: <span style="color: blue">{{ $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(buyTotal.toFixed(2))) }}</span> </b>
              </div>
              <div class="col-6">
                <b> Sell Turnover: <span style="color: red;">{{$helperService.getThousandConverter(
                          $helperService.getFormattedPrice(sellTotal.toFixed(2))) }}</span>
                </b>
              </div>
            </div>
            <div class="col-6 border-1 row">
              <div class="col-6">
                <b> Total Gross: <span :style="grossTotal > 0 ? 'color: blue' : 'color: red;'">{{ $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(grossTotal.toFixed(2)))
                }}</span>
                </b>
              </div>
              <div class="col-6">
                <b> Final Bill Amt: <span :style="finalTotal > 0 ? 'color: blue' : 'color: red;'">{{ $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(finalTotal.toFixed(2)))
                }}</span>
                </b>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PrintSettlement ref="printSettlement"></PrintSettlement>
    <DeleteModal @remove="deleteRecord($event)" @get-list="getList(0)" ref="deleteItem" />
  </div>
</template>
<script>
// import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
import PrintSettlement from '../../components/PrintSettlement.vue'

export default {
  components: {
    ErrorComponent,
    PrintSettlement,
    // Field,
    DeleteModal,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      name: this.$route.params.name,
      loader: true,
      type: "Default",
      list: [],
      fromDate: "",
      toDate: "",
      grossTotal: 0,
      finalTotal: 0,
      sellTotal: 0,
      buyTotal: 0,
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    getList() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }

      if (this.id) {
        searchObj.id = this.id;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      this.$api
        .getAPI({
          _action: "/user-settle-trades-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;

          if (this.$refs.errorComponent && res.list.length <= 0) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            this.grossTotal = 0;
            this.finalTotal = 0;
            this.buyTotal = 0;
            this.sellTotal = 0;
            var tempList = []
            for (var i in res.list) {
              res.list[i].datatype = 'DATA'
              var k = -1;
              for (var j in tempList) {
                if (tempList[j].trading_symbol.toUpperCase() == res.list[i].trading_symbol.toUpperCase()) {
                  k = j;
                }
              }

              var netamount = (res.list[i].net_price * res.list[i].quantity);
              if (res.list[i].order_action == "BUY") {
                netamount = -netamount;
              }


              if (res.list[i].order_action == "BUY") {
                this.buyTotal += res.list[i].price * res.list[i].quantity;
              } else {
                this.sellTotal += res.list[i].price * res.list[i].quantity;
              }

              var net_price = Math.abs((res.list[i].net_price * res.list[i].quantity) - (res.list[i].price * res.list[i].quantity))
              if (k == -1) {
                var temp = {
                  trading_symbol: res.list[i].trading_symbol, list: [res.list[i]], bq: res.list[i].order_action == "BUY" ? res.list[i].quantity : 0, sq: res.list[i].order_action == "SELL" ? res.list[i].quantity : 0, price: res.list[i].order_action == "BUY" ? -res.list[i].price * res.list[i].quantity : res.list[i].price * res.list[i].quantity, net_price: net_price, netamount: netamount
                };
                tempList.push(temp);
              } else {
                tempList[k].bq += res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                tempList[k].sq += res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                if (res.list[i].order_action == "SELL") {
                  tempList[k].price += res.list[i].price * res.list[i].quantity;
                } else {
                  tempList[k].price -= res.list[i].price * res.list[i].quantity;
                }

                tempList[k].net_price += net_price;
                tempList[k].netamount += netamount;
                tempList[k].list.push(res.list[i]);
              }
            }

            for (var m in tempList) {
              var obj = {
                datatype: 'Total',
                title: 'Script Wise total:',
                bq: tempList[m].bq,
                sq: tempList[m].sq,
                price: tempList[m].price,
                net_price: Math.round(tempList[m].net_price),
                netamount: tempList[m].netamount,
              }


              this.finalTotal += tempList[m].netamount
              this.grossTotal += tempList[m].price;
              tempList[m].list.push(obj);

            }


            this.$refs.errorComponent.updateFormLoader(false);
            this.list = tempList;
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Broker",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/account/" + detail.id);
    },
    download() {
      var name = this.name.split(')');
      this.$refs.printSettlement.setDataAndPrint(this.list, name[0] + ')', name[1] + ")")
    }
  },
};
</script>
